import React from "react";
import ogimage from "../assets/Images/ogImages/OG_DigirytePods.jpg";

import { BASE_SITE_URL } from "../shared/constants";
import Layout from "../components/layouts/layout";
import FormSection from "../components/FormSection";
import RevealAnimation from "../components/RevealAnimation";
import LocationCards from "../templates/OurLocation";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";
import Infoview from "../templates/InfoView";
import Box from "../templates/Box";
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import HeroTabSectionFaq from "../components/HeroTabSectionFaq";

const FaqPage = () => {
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Pune",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Our</span> Locations
      </div>
    ),
    InfoviewContent: (
      <>
        All our senior management team, tech consultants, client directors, and
        account managers are based in the UK. Our development team, consisting
        of the top 3% of premium professionals, is made up of British and Indian
        developers. We ensure that all project data is stored securely within UK
        borders, with access to the development team provided strictly on an
        as-needed basis.
      </>
    ),
  };

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "Career",
    tip: "Career",
    tooltip: "no",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1Title: "Join our team",
    boxBtn1SubTitle: (
      <>
        If you're a designer or a developer <br /> who loves to challenge
        conventions,
        <br /> make the ryte move!
      </>
    ),
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "Call us right now",
    boxBtn2Title: "Let's talk",
    tip1: "Call us right now",
    boxBtn2SubTitle: (
      <>
        Have you got an exciting project that <br /> you'd like to discuss?{" "}
      </>
    ),
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  return (
    <Layout
      seo={{
        title: "Digiryte Pods",
        ogtitle: "Digiryte Pods | Digiryte",
        description:
          "We assist in hiring, managing and implementing all the relevant human resource functions for you to start building & scaling a reliable and dynamic remote teams",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/digiryte-pods`,
      }}
    >
      <HeroTabSectionFaq />
      <div className="mt-70 common-bg-light">
        <FormSection />
      </div>
      <div className=" mt-70">
        <RevealAnimation animation="zoom-in" className="">
          <Infoview InfoviewData={InfoviewData} />
        </RevealAnimation>
        <div className="hide-in-mobile">
          <LocationCards
            mapImage={mapImage}
            label="View Location"
            LocationCardData={LocationCardData}
          />
        </div>
        <div className="hide-in-web">
          <LocationCards
            mapImage={mapImage1}
            label="View Location"
            LocationCardData={LocationCardData1}
          />
        </div>
        <div className="">
          <Box BoxData={BoxData} />
        </div>
        <div>
          <TwoColumnContactInfo
            TwoColumnContactInfoData={TwoColumnContactInfoData}
          />
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
